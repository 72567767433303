const uuid = sessionStorage.getItem('uuid') || null;

export default {
  namespaced: true,
  state: () => ({
    info: null,
    uuid: uuid,
  }),
  mutations: {
    setInfo(state, payload) {
      state.info = payload;
    },
    setUuid(state, payload) {
      state.uuid = payload;
      if (payload) {
        sessionStorage.setItem('uuid', payload);
      } else {
        sessionStorage.removeItem('uuid');
        localStorage.removeItem('lastConnectType');
        localStorage.removeItem('lastConnectTime');
      }
    },
  },
  actions: {
    setInfo(context, payload) {
      context.commit('setInfo', payload);
    },
    setUuid(context, payload) {
      context.commit('setUuid', payload);
    },
  },
  getters: {
    info(state) {
      return state.info;
    },
    uuid(state) {
      return state.uuid;
    },
  },
};
