import '@/style/normalize.less';
import '@/style/module.less';
import ElementPlus from 'element-plus';
import { createApp } from 'vue';
import router from '@/router';
import store from '@/store';
import filter from '@/filter';
import i18n from '@/i18n';
import App from './App.vue';
import { initGlobal } from './init';

const app = createApp(App);
app.use(filter);
app.use(store);
app.use(router);
app.use(i18n);
app.use(ElementPlus);

initGlobal(app).finally(() => {
  app.mount('#root');
});
