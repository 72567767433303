import { addFontSize } from '@/util';
import { restoreConnect } from '@/lib/connect';
import { initWebSocket } from '@/lib/websocket';

async function initBitconJs() {
  const btcjs = window.bitcoin;
  const secp256k1 = await import('tiny-secp256k1');
  btcjs.initEccLib(secp256k1);
}

// initGlobal
export async function initGlobal(app) {
  addFontSize();
  window.addEventListener('resize', addFontSize);

  // init websocket
  initWebSocket().catch((err) => {
    console.error(err);
  });

  // init wallet connect
  restoreConnect().catch((err) => {
    console.error(err);
  });

  // init bitcoin
  initBitconJs();
}
