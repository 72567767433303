export default {
  namespaced: true,
  state: () => ({
    dialogVisible: false,
    connectedState: 0, // 0: fail, 1:success
    network: localStorage.getItem('network') || 'mainnet',
  }),
  mutations: {
    setDialogVisible(state, payload) {
      state.dialogVisible = payload;
    },
    setConnectedState(state, payload) {
      state.connectedState = payload;
    },
    setNetwork(state, payload) {
      state.network = payload;
      localStorage.setItem('network', payload || '');
    },
  },
  actions: {
    setDialogVisible(context, payload) {
      context.commit('setDialogVisible', payload);
    },
    setConnectedState(context, payload) {
      context.commit('setConnectedState', payload);
    },
    setNetwork(context, payload) {
      context.commit('setNetwork', payload);
    },
    openConnectDialog(context) {
      context.commit('setDialogVisible', true);
    },
    closeConnectDialog(context) {
      context.commit('setDialogVisible', false);
    },
  },
  getters: {
    dialogVisible(state) {
      return state.dialogVisible;
    },
    connectedState(state) {
      return state.connectedState;
    },
    network(state) {
      return state.network;
    },
  },
};
