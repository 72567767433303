<template>
  <el-dialog
    v-model="dialogVisible"
    append-to-body
    destroy-on-close
    align-center
    :title="$t('connect.title')"
    @closed="handleClose"
    class="connectDialog"
    width="auto"
  >
    <div class="content">
      <div class="list">
        <div class="item" @click="handleConnect('unisat')">
          <img src="/img/icon-unisat.png" />
          <span>Unisat</span>
          <el-icon><ArrowRight /></el-icon>
        </div>
        <div class="item" @click="handleConnect('metalet')">
          <img src="/img/icon-metalet.png" />
          <span>Metalet</span>
          <el-icon><ArrowRight /></el-icon>
        </div>
      </div>
      <div class="bottom-text">
        <div class="agree">{{ $t('connect.agree') }}</div>
        <div class="terms">
          <a
            href="https://orders-exchange.gitbook.io/orders/risks-and-disclaimer/risks-and-disclaimer"
            target="_blank"
            >{{ $t('connect.terms') }}</a
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { ArrowRight } from '@element-plus/icons-vue';
import { connectWallet } from '@/lib/connect';
import { ElLoading } from 'element-plus';

export default {
  name: 'co-connect-dialog',
  components: {
    ArrowRight,
  },
  computed: {
    ...mapGetters('connect', ['dialogVisible', 'connectType']),
  },
  methods: {
    ...mapActions({
      closeConnectDialog: 'connect/closeConnectDialog',
    }),
    handleClose() {
      this.closeConnectDialog();
    },
    async handleConnect(type) {
      if (type == 'metalet') {
        this.$message('即将上线,敬请期待');
        return;
      }
      const loading = ElLoading.service({ background: 'rgba(0,0,0,0.5)' });
      await connectWallet(type)
        .then((res) => {
          this.$message.success(this.$t('connect.success'));
        })
        .catch((err) => {
          this.$message.error(this.$t('connect.fail') + ',' + err);
        })
        .finally(() => {
          loading.close();
          this.handleClose();
        });
    },
  },
};
</script>
<style lang="less">
.connectDialog {
  border-radius: 12px;
  background: #1c1929;
  box-shadow: 0px 0px 6px 0px rgba(180, 192, 201, 0.52);
  min-width: 680px;

  .el-dialog__header {
    text-align: center;

    .el-dialog__title {
      font-size: 24px;
      font-weight: bold;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
    }

    .el-dialog__headerbtn {
      font-size: 24px;
    }
  }

  .content {
    padding: 30px;

    .list {
      .item {
        height: 72px;
        border-radius: 12px;
        background: #171222;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 12px 30px;
        margin-bottom: 30px;
        cursor: pointer;
        transition: background 0.2s linear;
        &:hover {
          background-color: #352b4c;
        }

        img {
          width: 48px;
          height: 48px;
          border-radius: 48px;
        }

        span {
          margin-left: 20px;
          font-size: 24px;
          color: #ffffff;
          font-weight: bold;
          flex: 1;
        }

        .el-icon {
          font-size: 20px;
          color: #fff;
        }
      }
    }

    .bottom-text {
      .agree {
        font-size: 18px;
        text-align: center;
        color: #cdcdcd;
      }
      .terms {
        font-size: 16px;
        text-align: center;
        color: #6a6280;
        margin-top: 24px;

        a {
          color: #6a6280;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
