export default {
  name: 'EN',
  common: {
    pleaseInput: 'Please Input',
    comingSoon: 'The function is coming soon, please wait',
    menu: 'Menu',
    confirmation: 'Confirmation',
    confirm: 'Confirm',
    cancel: 'Cancel',
    loadMore: 'Load More',
    noData: 'No Data',
    noTickers: 'The system has no ticker to transaction',
    orderProcessing: 'The order is processing',
  },
  home: {
    title: 'Orders.Exchange',
    subtitle: 'The First Dex Built On Bitcoin',
    desc: 'Orders.Exchange is a decentralized exchange for BRC-20 and Runes, entirely built on Bitcoin. You can trade, list and inscribe your BRC-20 & Runes.',
    launch: 'Launch',
    docs: 'Docs',
    Whitepaper: 'Whitepaper',
    title2: 'We Are The First',
    card1: 'OrderBook Dex in Bitcoin',
    card1Desc:
      'OrderBook Dex is a bitcoin-based decentralized exchange (DEX). It uses smart contracts and distributed ledger technology to automate and transparently trade without the need for a central authority.',
    card2: 'PSBT technology',
    card2Desc:
      'Orders marks the first application of PSBT (Partially Signed Bitcoin Transactions), bolstering transaction efficiency and security. This innovative approach enables Orders to facilitate intricate transactions, thereby fortifying the Bitcoin network.',
    card3: 'Nostr, Building Interconnected DEX',
    card3Desc:
      'Orders stands as the first to harness the groundbreaking Nostr protocol, revolutionizing the realm of decentralized exchanges (DEXs) within Bitcoin. By fostering DEX interoperability, Orders paves the way for a more interconnected and efficient trading ecosystem, propelling Bitcoin-based DeFi to uncharted territories.',
    title3: 'Why Use Orders.Exchange',
    title4: 'Partnerships',
    capability: 'Capability',
    fund: 'Funds kept in non-custodial wallets',
    onchain: 'Onchain Trading',
    free: 'Free from exchange hacks',
    run: 'Run on Bitcoin',
    support: 'Support BRC-20',
    based: 'Based on PSBT',
    instant: 'Instant Deposit / Withdraw',

    cex: 'CEX',
    otherCex: 'Other DEX(On ETH)',
    orders: 'Orders',

    yes: 'YES',
    no: 'NO',
  },
  tutorials: {
    title: 'Tutorials',
    content1: {
      title: 'How to Use BID Order and Participate in the Liquidity Pool',
    },
    content2: {
      title: 'How to import a wallet into OKX Web3 Wallet and purchase $RDEX?',
    },
    content3: {
      title: 'Creating Bid/Ask Orders and Buying/Selling Tutorial',
    },
    content4: {
      title: 'Orders V2 Tutorial - DEX Module',
    },
  },
  tokenomic: {
    content1: {
      title: 'Join Us By Holding $RDEX',
      price: 'Price',
      mcc: 'Market Cap',
      supply: 'Circulating Supply',
    },
    content2: {
      title: '$RDEX Distribution',
      detail: 'More Details',
    },
    content3: {
      title: '$RDEX is listed on',
    },
    content4: {
      title: 'Economics',
      content: {
        desc1:
          'The transaction fees generated by BID orders will be used to repurchase $RDEX listed on Orders.Exchange at a frequency of once every 48 blocks and burned.',
        desc2: '$RDEX may be used to pay platform service fees (currently under technical investigation).',
        desc3: '$RDEX may be used as an entry threshold for LaunchPad (currently under technical investigation).',
        desc4: 'Holding $RDEX might offer fee rate discounts (currently under technical investigation).',
      },
    },
  },
  launchpad: {
    title: 'IDO',
    description: 'Participate in the IDOs of exciting projects emerging on Orders.',
  },
  layout: {
    connect: 'Connect',
    logout: 'Logout',
    market: 'Market',
    trade: 'Trade',
    inscribe: 'Inscribe',
    launchpad: 'Launchpad',
    tokenomics: 'Tokenomics',
    changeNet: 'Change Network',
    copyAddress: 'Copy Address',
  },
  connect: {
    title: 'Connect Wallet',
    agree: 'I Agree',
    terms: 'Terms Of Use',
    success: 'Connect Success',
    fail: 'Connect Fail',
  },
  transaction: {
    orders: {
      title: 'Order list',
      price: 'price',
      vol: 'volume',
      total: 'total',
    },
    table: {
      type: 'type',
      price: 'price',
      vol: 'volume',
      total: 'total',
      time: 'time',
      state: 'state',
      operation: 'operation',
      revoke: 'revoke',
      myorders: 'my orders',
      allorders: 'all orders',
      sellLimit: 'sell Limit',
      buyAtCurrent: 'buy at current',
    },
    rate: {
      priceChangePercent: '24h percentage',
      highest: '24h highest',
      lowest: '24h lowest',
      volumn: '24h volume',
      all: 'all',
      search: 'search',
      custom: 'custom',
      pair: 'pair',
      latestPrice: 'latestPrice',
      percentage: 'percentage',
    },
    trade: {
      buy: 'buy',
      sell: 'sell',
      limitOrder: 'limit order',
      marketOrder: 'market order',
      amount: 'amount',
      quantity: 'quantity',
      totalPrice: 'total',
      serviceCharge: 'service charge',
      balance: 'balance',
      price: 'price',
      confirmToContinue: 'Confirm to continue',
    },
    history: {
      title: 'history',
      date: 'date',
      price: 'price',
      amount: 'amount',
    },
  },
};
