import store from '@/store';

export async function beforeEach(to, from, next) {
  try {
    if (import.meta.env.MODE === 'production') {
      const res = await fetch(`https://api2.orders.exchange/api/geo`);
      const data = await res.json();
      console.log('data', data);
      if (data == 'CN') {
        window.location.href = `https://app.orders.exchange/not-available`;
      } else {
        next();
      }
    } else {
      next();
    }
  } catch (error) {
    window.location.href = `https://app.orders.exchange/not-available`;
  }
}

export async function afterEach(to) {
  document.title = to?.meta?.title || store.state.system.config.CONFIG_APP_NAME || '';
}
