// import { RouterView } from 'vue-router';

export default [
  {
    name: 'home',
    path: '/',
    component: () => import('@/page/home/index.vue'),
    meta: {},
  },
  {
    name: 'launchpad',
    path: '/launchpad',
    component: () => import('@/page/launchpad/index.vue'),
    meta: {},
  },
  {
    name: 'transaction',
    path: '/transaction',
    component: () => import('@/page/transaction/index.vue'),
    meta: {},
  },
  {
    name: 'tokenomics',
    path: '/tokenomics',
    component: () => import('@/page/tokenomics/index.vue'),
    meta: {},
  },
  {
    name: 'tutorials',
    path: '/tutorials',
    component: () => import('@/page/tutorials/index.vue'),
    meta: {},
  },
];
