export const orderState = {
  1: 'create',
  2: 'finish',
  3: 'cancel',
  5: 'timeout',
  6: 'error',
};

export const orderType = {
  1: 'sell',
  2: 'buy',
};

export const DUMMY_UTXO_VALUE = 600;
export const ORD_UTXO_VALUE = 1000;

// feeb
export const MIN_FEEB = 10;
export const EXTREME_FEEB = 1;
export const FEEB_MULTIPLIER = 1.4;

// exchange
export const SERVICE_TESTNET_ADDRESS = 'tb1pwzgaktsp28weq69y6dsrvnn7s3f7mdd5prep6uzwv6yjmehkeyqs3axgu3'
;
export const SERVICE_LIVENET_ADDRESS = 'bc1qwqr3vscc2k9x7rhl5ldgsnd6ykh35a5amu4r2h';
