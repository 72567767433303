<template>
  <div class="app">
    <el-config-provider :locale="providerLocale">
      <router-view></router-view>
      <ConnectDialog />
    </el-config-provider>
  </div>
</template>
<script>
import store from './store';
import { mapGetters } from 'vuex';
import ElementPlusLocaleZhCn from 'element-plus/dist/locale/zh-cn';
import ElementPlusLocaleEn from 'element-plus/dist/locale/en';
import ConnectDialog from '@/components/connectDialog';

export default {
  name: 'app',
  computed: {
    ...mapGetters('system', ['locale']),
  },
  watch: {
    locale() {
      this.initLocale();
    },
  },
  components: {
    ConnectDialog,
  },
  data() {
    return {
      providerLocale: store.state.system.locale === 'en' ? ElementPlusLocaleEn : ElementPlusLocaleZhCn,
    };
  },
  methods: {
    async getIpLocation() {
      if (import.meta.env.MODE === 'production') {
        const res = await fetch(`https://api2.orders.exchange/api/geo`);
        const data = await res.json();
        console.log('data', data);
        if (data == 'CN') {
          window.location.href = `https://app.orders.exchange/not-available`;
        }
      }
    },

    initLocale() {
      this.providerLocale = this.locale === 'en' ? ElementPlusLocaleEn : ElementPlusLocaleZhCn;
    },
  },
  async created() {
    // await this.getIpLocation();
  },
};
</script>
<style lang="less">
html,
body {
  font-family: 'GeistMono-Regular';
}
</style>
