import { createStore } from 'vuex';
import system from './modules/system';
import user from './modules/user';
import connect from './modules/connect';

const store = createStore({
  modules: {
    system,
    user,
    connect,
  },
});

export default store;
