<template>
  <div class="layout">
    <div class="header">
      <div class="header-wrapper">
        <!-- 首页logo -->
        <router-link :to="homePath" class="logo">
          <img :src="appLogoName" />
          <span>{{ network }}</span>
        </router-link>

        <div class="tabs">
          <template v-for="(tab, index) in tabList" :key="index">
            <div :class="['item', currentPath === tab.path ? 'active' : '']" @click="handleMenuCommand(tab.path)">
              <a>
                <span>{{ tab.label }}</span>
              </a>
            </div>
          </template>
        </div>

        <div class="right-nav">
          <!-- 手机版菜单 -->
          <div class="menu">
            <el-dropdown @command="handleMenuCommand" placement="bottom-end">
              <span class="el-dropdown-link" style="margin-bottom: 4px">
                <span>{{ $t('common.menu') }}</span>
                <el-icon>
                  <CaretBottom />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <template v-for="(tab, key) in tabList" :key="key">
                    <el-dropdown-item :command="tab.path">{{ tab.label }}</el-dropdown-item>
                  </template>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <!-- 教程 -->

          <div class="tutorials">
            <a @click="toTutorials">
              <img :src="video" alt="" />
              <span>{{ $t('tutorials.title') }}</span>
            </a>
          </div>
          <!-- 分隔符 -->
          <span class="split-line"></span>
          <!-- 语言 -->
          <div class="lang">
            <el-dropdown @command="handleLangCommand" placement="bottom-end">
              <span class="el-dropdown-link">
                <span>{{ currentLocale }}</span>
                <el-icon>
                  <CaretBottom />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <template v-for="(value, key) in langCollection" :key="key">
                    <el-dropdown-item :command="key">{{ value.name || key }}</el-dropdown-item>
                  </template>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <!-- 登录钱包 -->
          <div class="user">
            <template v-if="userInfo">
              <el-dropdown @command="handleConnectCommand" placement="bottom-end">
                <span class="el-dropdown-link connected-user">
                  <img v-if="userInfo.avatar" :src="userInfo.avatar" class="usericon" />
                  <span class="username">{{ userInfo.name || '未命名用户' }}</span>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command="copy">{{ $t('layout.copyAddress') }}</el-dropdown-item>
                    <el-dropdown-item command="changeNet">{{ $t('layout.changeNet') }}</el-dropdown-item>
                    <el-dropdown-item command="logout">{{ $t('layout.logout') }}</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
            <template v-else>
              <button class="connected-user button" @click="handleConnectCommand('connect')">
                {{ $t('layout.connect') }}
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="main-content">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { Menu, CaretBottom } from '@element-plus/icons-vue';
import { langCollection } from '@/i18n';
import { switchNetwork } from '@/lib/connect';
import videoIcon from '/public/img/home/video-icon.svg?url';

export default {
  name: 'layout',
  components: {
    Menu,
    CaretBottom,
  },
  data() {
    return {
      video: videoIcon,
      langCollection,
    };
  },

  computed: {
    ...mapGetters('system', ['config', 'locale']),
    ...mapGetters('user', ['info']),
    ...mapGetters('connect', ['network']),
    appLogo() {
      return this.config?.CONFIG_APP_LOGO || '';
    },
    appLogoName() {
      return this.config?.CONFIG_APP_LOGO_NAME || '';
    },
    appName() {
      return this.config?.CONFIG_APP_NAME || '';
    },
    homePath() {
      return this.config?.CONFIG_HOME_PATH || '';
    },
    userInfo() {
      return this.info || null;
    },
    currentLocale() {
      console.log('132131', langCollection[this.locale].name, this.locale);
      return langCollection[this.locale].name || this.locale;
    },
    currentPath() {
      return this.$route.path;
    },
    tabList() {
      return [
        { path: '/transaction', label: this.$t('layout.trade') },
        { path: '', label: this.$t('layout.market') },
        { path: '', label: this.$t('layout.inscribe') },
        { path: '', label: this.$t('layout.launchpad') },
        { path: '/tokenomics', label: this.$t('layout.tokenomics') },
      ];
    },
  },
  methods: {
    ...mapActions({
      setLocale: 'system/setLocale',
      setInfo: 'user/setInfo',
      setUuid: 'user/setUuid',
      openConnectDialog: 'connect/openConnectDialog',
      setConnectedState: 'connect/setConnectedState',
    }),
    toTutorials() {
      this.$router.push('/tutorials');
    },
    handleConnectCommand(e) {
      switch (e) {
        case 'changeNet': {
          const targetNetwork = this.network === 'testnet' ? 'mainnet' : 'testnet';
          switchNetwork(targetNetwork).finally(() => {
            window.location.reload();
          });
          break;
        }
        case 'connect': {
          this.openConnectDialog();
          break;
        }
        case 'logout': {
          this.setConnectedState(0);
          this.setInfo(null);
          this.setUuid(null);
          break;
        }
      }
    },
    handleLangCommand(e) {
      this.setLocale(e);
    },
    showComingSoonTip() {
      this.$message.info(this.$t('common.comingSoon'));
    },
    handleMenuCommand(e) {
      if (e.includes('transaction')) {
        window.open('https://app.orders.exchange', '_self');
        return;
      }
      if (e) {
        this.$router.push(e);
      } else {
        this.showComingSoonTip();
      }
    },
  },
};
</script>
<style lang="less" src="./index.less"></style>
