import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { orderState, orderType } from '@/config/constant';

// format orderState
export function formatOrderState(key, defaultValue = '--') {
  return orderState[key] || defaultValue;
}

// format orderType
export function formatOrderType(key, defaultValue = '--') {
  return orderType[key] || defaultValue;
}

// format date
export function formatDate(val, fmt = 'YYYY-MM-DD HH:mm:ss') {
  if (!val) {
    return '';
  }
  return dayjs(val).format(fmt);
}

// route to menu
export function routesToMenus(routes) {
  const menus = [];
  for (let i = 0; i < routes.length; i++) {
    const r = routes[i];
    if (r?.meta?.notInMenu) {
      continue;
    }
    const m = {
      id: r.name,
      name: r?.meta?.title || r.name,
      path: r.path.indexOf('/') < 0 ? `/${r.path}` : r.path,
    };
    if (r.children && r.children.length) {
      m.children = routesToMenus(r.children);
    }
    menus.push(m);
  }
  return menus;
}

// html body fontSize
export function addFontSize() {
  const screenWidth = Math.min(window.innerWidth, document.body.clientWidth, window.screen.availWidth);
  const html = document.querySelector('html');
  const body = document.querySelector('body');
  if (screenWidth > 1000) {
    html.style.fontSize = '100px';
    body.style.fontSize = '14px';
  } else {
    const fontSize = (screenWidth / 750) * 100;
    html.style.fontSize = fontSize + 'px';
    body.style.fontSize = 0.28 + 'rem';
  }
}

// reset request params
export function resetParams(obj, exclude = ['page', 'size']) {
  for (let key in obj) {
    if (!exclude.includes(key)) {
      obj = undefined;
    }
  }
}

// split 1 pair str to 2 ticker obj
export function splitPairToTickers(obj, key = 'pair') {
  if (!obj || !obj[key] || obj[key].indexOf('-') < 0) {
    return obj;
  }
  let [one, two] = obj[key].split('-');
  if (!one) {
    one = 'NONE';
  }
  if (!two) {
    two = 'NONE';
  }
  const t1 = {
    name: one.toUpperCase(),
    icon: '/img/tickers/' + one.toUpperCase() + '.png',
    unit: one,
  };
  const t2 = {
    name: two.toUpperCase(),
    icon: '/img/tickers/' + two.toUpperCase() + '.png',
    unit: two,
  };
  obj.t1 = t1;
  obj.t2 = t2;
  obj[key] = `${one}-${two}`;
  return obj;
}

// unit change: sa -> btc
export function coinPrice(val, toFixed = 0) {
  if (!val) {
    return val;
  }
  const d = new Decimal(val || 0).div(new Decimal(Math.pow(10, 8)));
  if (toFixed) {
    return d.toFixed(toFixed);
  }
  return d.toString();
}

export function filterOrders(newOrderList, oldOrderList) {
  let uniqueList = newOrderList.filter((order) => {
    return !oldOrderList.find((old) => {
      return old.orderId === order.orderId;
    });
  });
  return uniqueList;
}
