let config = {}

try {
  config = JSON.parse(decodeURIComponent(atob(window.APPCONF)))
  config.env = window.ENV
  config.mode = window.MODE
} catch(err) {
}

export default {
  ...config
};
