import axios from 'axios';
import config from '@/config';
import { ElLoading, ElMessage } from 'element-plus';

const defaultAxiosOptions = {
  url: '',
  baseURL: config.CONFIG_API_HOST,
  params: undefined,
  data: undefined,
  header: undefined,
  method: 'GET',
  cancelToken: undefined,
};
const defaultRequestOptions = {
  showLoading: false,
  showError: true,
  targetCode: 0,
  takeInnerData: true,
};

const Request = axios.create();
Request.interceptors.request.use(
  function (config) {
    const { requestOptions = defaultRequestOptions } = config;
    if (requestOptions.showLoading) {
      config.loading = ElLoading.service();
    }
    if (!config.headers) {
      config.headers = {};
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
Request.interceptors.response.use(
  function (response) {
    const { requestOptions = defaultRequestOptions, loading } = response.config;
    if (loading && requestOptions.showLoading) {
      loading.close();
      delete config.loading;
    }

    const data = response.data;
    if (typeof data === 'object' && 'code' in data && 'message' in data) {
      const code = data.code;
      const message = data.message;
      if (code !== requestOptions.targetCode) {
        if (requestOptions.showError) {
          ElMessage({
            message,
            type: 'error',
            showClose: true,
          });
        }
        throw data;
      }
    }

    let finalData = data;
    if (requestOptions.takeInnerData && typeof data === 'object' && 'data' in data) {
      finalData = data.data;
    }
    return finalData;
  },
  function (error) {
    if (error instanceof axios.AxiosError) {
      const { response } = error;
      if (!response) {
        return Promise.reject(error);
      }

      const { requestOptions = defaultRequestOptions, loading } = response.config;
      if (loading && requestOptions.showLoading) {
        loading.close();
        delete config.loading;
      }

      const data = response.data;
      if (typeof data === 'object' && 'code' in data && 'message' in data) {
        const code = data.code;
        const message = data.message;
        if (code !== requestOptions.targetCode) {
          if (requestOptions.showError) {
            ElMessage({
              message,
              type: 'error',
              showClose: true,
            });
          }
          return Promise.reject(data);
        }
      }

      let finalData = data;
      if (requestOptions.takeInnerData && typeof data === 'object' && 'data' in data) {
        finalData = data.data;
      }
      return Promise.reject(finalData);
    }
    return Promise.reject(error);
  }
);

export function request(axiosOptions = defaultAxiosOptions, requestOptions = defaultRequestOptions) {
  axiosOptions = Object.assign({}, defaultAxiosOptions, axiosOptions);
  requestOptions = Object.assign({}, defaultRequestOptions, requestOptions);
  return Request({
    ...axiosOptions,
    requestOptions,
  });
}

export function get(axiosOptions = defaultAxiosOptions, requestOptions = defaultRequestOptions) {
  axiosOptions.method = 'GET';
  return request(axiosOptions, requestOptions);
}

export function post(axiosOptions = defaultAxiosOptions, requestOptions = defaultRequestOptions) {
  axiosOptions.method = 'POST';
  return request(axiosOptions, requestOptions);
}
