import config from '@/config';

export default {
  namespaced: true,
  state: () => ({
    config,
    env: config.env,
    mode: config.mode,
    locale: localStorage.getItem('locale') || 'en',
  }),
  mutations: {
    setConfig(state, payload) {
      state.config = Object.assign(state.config, payload);
    },
    setLocale(state, payload) {
      state.locale = payload;
      localStorage.setItem('locale', payload || 'en');
    },
  },
  actions: {
    setConfig(context, payload) {
      context.commit('setConfig', payload);
    },
    setLocale(context, payload) {
      context.commit('setLocale', payload);
    },
  },
  getters: {
    config(state) {
      return state.config;
    },
    locale(state) {
      return state.locale;
    },
  },
};
