import { createRouter, createWebHistory } from 'vue-router';
import Layout from '@/layout/index.vue';
import routes from './routes';
import { beforeEach, afterEach } from './navigationGuards';

// init router
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: 'layout',
      path: '/',
      component: Layout,
      children: [...routes],
    },
    {
      name: 'demo',
      path: '/demo',
      component: () => import('@/page/demo/index.vue'),
    },
    {
      name: 'notFound',
      path: '/:pathMatch(.*)*',
      component: () => import('@/page/error/index.vue'),
      meta: {
        needAuth: false,
      },
    },
  ],
});

// add router navigationGuards
router.beforeEach(beforeEach);
router.afterEach(afterEach);

export default router;
