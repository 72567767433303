import config from '@/config';
import { WsUuid } from '@/api/brc20';
import store from '@/store';

let globalWs = null;

const defaultWebSocketOptions = {
  url: '',
  baseURL: config.CONFIG_API_SOCKET_HOST,
  showError: true,
  targetCode: 0,
  takeInnerData: true,
  onOpen: undefined,
  onMessage: undefined,
  onClose: undefined,
  onError: undefined,
};

const defaultRequestOptions = {
  showLoading: false,
  showError: true,
  targetCode: 0,
  takeInnerData: true,
};

class AppWebSocket {
  constructor(options = defaultWebSocketOptions, requestoptions = defaultRequestOptions) {
    this.ws = null;
    this.options = Object.assign({}, defaultWebSocketOptions, options);
    this.requestoptions = Object.assign({}, defaultRequestOptions, requestoptions);
    this.eventMap = {};
    this.initWs();
  }

  initWs() {
    this.ws = new window.WebSocket(this.options.baseURL + this.options.url);
    this.ws.onopen = this.onOpen.bind(this);
    this.ws.onmessage = this.onMessage.bind(this);
    this.ws.onclose = this.onClose.bind(this);
    this.ws.onerror = this.onError.bind(this);
  }

  onOpen(e) {
    this.sendHeartBeat();
    if (typeof this.options.onOpen === 'function') {
      this.options.onOpen(e);
    }
  }

  onMessage(e) {
    let { data: eventData } = e;
    let method, code, data;
    try {
      if (typeof eventData === 'string') {
        eventData = JSON.parse(eventData);
      }
      if (typeof eventData === 'object') {
        method = eventData.M || '';
        code = eventData.C || 0;
        data = eventData.D || null;
      }
    } catch (err) {
      console.error(err);
    }
    if (typeof this.options.onMessage === 'function') {
      this.options.onMessage(e, method, data, code, eventData);
    }
    if (method in this.eventMap) {
      this.eventMap[method]({
        method,
        data,
        code,
        eventData,
        event: e,
      });
    }
  }

  onClose(e) {
    if (typeof this.options.onClose === 'function') {
      this.options.onClose(e);
    }
  }

  onError(e) {
    if (typeof this.options.onError === 'function') {
      this.options.onError(e);
    }
  }

  addMessageEventListener(eventName, callback) {
    this.eventMap[eventName] = callback;
  }

  removeMessageEventListener(eventName, callback) {
    if (eventName in this.eventMap) {
      delete this.eventMap[eventName];
    }
    if (callback && typeof callback === 'function') {
      callback();
    }
  }

  send(data) {
    if (typeof data === 'string' || data instanceof ArrayBuffer || data instanceof Blob) {
      this.ws.send(data);
    } else {
      this.ws.send(JSON.stringify(data));
    }
  }

  sendHeartBeat() {
    this.send({
      M: 'HEART_BEAT',
    });
  }

  close() {
    this.ws.close(...arguments);
  }
}

// init websocket
export async function initWebSocket() {
  if (!store.state.user.uuid) {
    const res = await WsUuid()
      .then((res) => res.uuid)
      .catch((err) => null);
    if (res) {
      store.dispatch('user/setUuid', res);
    }
  }

  let heartBeatTimer;
  let reconnectTimer;
  let reconnectTimeCount = 0;

  globalWs = new AppWebSocket({
    url: `/ws-service?uuid=${store.state.user.uuid}`,
    onClose() {
      clearInterval(heartBeatTimer);
      if (reconnectTimeCount >= 60) {
        return;
      }
      reconnectTimer = setTimeout(() => {
        reconnectTimeCount += 1;
        globalWs.initWs();
      }, 5000);
    },
    onOpen() {
      clearTimeout(reconnectTimer);
      reconnectTimeCount = 0;

      globalWs.addMessageEventListener('HEART_BEAT', function (obj) {
        // console.log('HEART_BEAT:',obj);
      });

      heartBeatTimer = setInterval(() => {
        globalWs.sendHeartBeat();
      }, 5000);
    },
  });
}

// event
export function addMessageEventListener(eventName, callback) {
  return globalWs.addMessageEventListener(eventName, callback);
}

export function removeMessageEventListener(eventName, callback) {
  return globalWs.removeMessageEventListener(eventName, callback);
}

export function getWs() {
  return globalWs;
}
