import { get, post } from '@/lib/request';

// https://api.ordbook.io/book/swagger/index.html#/

export function WsUuid(params) {
  return get({
    url: '/book/brc20/ws/uuid',
    params,
  });
}

export function GetRDEXPrice() {
  return get({
    url: `https://www.metalet.space/wallet-api/v3/coin/brc20/price`,
  });
}

export function GetRDEXSupply() {
  return get({
    url: `/book/brc20/supply/info`,
  });
}

export function Tickers(params) {
  return get({
    url: '/book/brc20/tickers',
    params,
  });
}

export function Orders(params) {
  return get({
    url: '/book/brc20/orders',
    params,
  });
}

export function OrdersUser(params, address) {
  return get({
    url: '/book/brc20/orders/user/' + address,
    params,
  });
}

export function Klines(params) {
  return get({
    url: '/book/brc20/kline',
    params,
  });
}

export function OrderBidPre(params) {
  return get({
    url: '/book/brc20/order/bid/pre',
    params,
  });
}

export function OrderBid(params) {
  return get({
    url: '/book/brc20/order/bid',
    params,
  });
}

export function OrderBidPush(data) {
  return post({
    url: '/book/brc20/order/bid/push',
    data,
  });
}

export function AddressTick(params, address, tick) {
  return get({
    url: `/book/brc20/address/${address}/${tick}`,
    params,
  });
}
