import store from '@/store';
import langEn from './en';
import langZh from './zh';

export const langCollection = {
  en: langEn,
  zh: langZh,
};

function getLangMessage(steps, options) {
  const { lang = store.state.system.locale, toUpperCamelCase = false } = options || {};
  if (!steps) {
    return '';
  }
  const target = langCollection[lang];
  const stepArr = steps.split('.');
  let stepObj = target;
  while (stepArr.length) {
    const key = stepArr.shift();
    if (key in stepObj) {
      stepObj = stepObj[key];
    }
  }
  let str = typeof stepObj === 'string' ? stepObj : steps;
  if (str && typeof str === 'string' && toUpperCamelCase) {
    str = str[0].toUpperCase() + str.slice(1);
  }
  return str;
}

function i18n(steps, options) {
  return getLangMessage(steps, options);
}
i18n.install = (app) => {
  app.config.globalProperties.$t = getLangMessage;
};

export default i18n;
