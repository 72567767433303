export default {
  name: 'ZH',
  common: {
    pleaseInput: '请输入',
    comingSoon: '该功能即将上线,敬请期待',
    menu: '菜单',
    confirmation: '订单确认',
    confirm: '确认',
    cancel: '取消',
    loadMore: '加载更多',
    noData: '暂无数据',
    noTickers: '当前系统暂无可交易的交易对',
    orderProcessing: '订单处理中',
  },
  home: {
    title: 'Orders.Exchange',
    subtitle: '第一个基于比特币为比特币构建的Dex',
    desc: 'Orders.Exchange是BRC-20的去中心化交易所，完全建立在比特币之上。您可以交易、列出并在BRC-20上签名',
    launch: '登录',
    docs: '文档',
    Whitepaper: '白皮书',
    title2: '我们是第一',
    card1: 'OrderBook Dex在比特币之中',
    card1Desc:
      'OrderBook Dex是一个基于比特币的去中心化交易所（Dex）。它使用智能合约和分布式账本技术实现交易自动化和透明化，而不需要中央机构。',
    card2: 'PSBT技术',
    card2Desc:
      'Orders是首个采用PSBT（部分签名比特币交易）的交易所，这种技术提升了交易的效率和安全性。这种创新的方法使Orders能够处理复杂的交易，从而强化了比特币网络。',
    card3: 'Nostr，打造互联互通的DEX',
    card3Desc:
      'Orders也是首个利用Nostr协议的项目，此协议能够让Orders和其他采用比特币网络的交易所彻底区分开来。通过Nostr协议可以促进DEXs的互操作性，让Orders开创了一个更加互联和高效的交易生态系统，将基于比特币的DeFi推向了前所未有的领域。',
    title3: '为什么使用订单交换',
    title4: '合作伙伴',
    capability: '能力',
    fund: '存放在非托管钱包中的资金',
    onchain: '在线交易',
    free: '免受交换黑客攻击',
    run: '在比特币上运行',
    support: '支持BRC-20',
    based: '基于PSBT',
    instant: '即时充值 / 提币',
    cex: 'CEX',
    otherCex: '其他DEX(ETH)',
    orders: 'Order Dex',
    yes: '是',
    no: '否',
  },
  launchpad: {
    title: 'IDO',
    description: '参与订单上出现的激动人心的项目的IDO',
  },
  tokenomic: {
    content1: {
      title: '通过持有 $RDEX 加入我们',
      price: '价格',
      mcc: '市值',
      supply: '流通量',
    },
    content2: {
      title: '$RDEX 分发',
    },
    content3: {
      title: '$RDEX 已上市',
    },
    content4: {
      title: '经济学',
      content: {
        desc1: 'BID订单产生的交易手续费将被用于以每48个区块的频率，在Orders.Exchange上回购$RDEX，并进行销毁。',
        desc2: '$RDEX可以用于支付平台服务费用（目前正在进行技术调试）',
        desc3: '$RDEX可能被用作LaunchPad的入场门槛（目前正在进行技术调试）。',
        desc4: '持有$RDEX可能会提供手续费折扣（目前正在进行技术调试）。',
      },
    },
  },
  layout: {
    connect: '连接钱包',
    logout: '退出登录',
    market: '市场',
    trade: '交易',
    inscribe: '铭刻',
    launchpad: '启动',
    tokenomics: '代币经济',
    changeNet: '切换网络',
    copyAddress: '复制地址',
  },
  connect: {
    title: '连接钱包',
    agree: '通过连接，我接受',
    terms: '使用条款',
    success: '连接成功',
    fail: '连接失败',
  },
  tutorials: {
    title: '教程',
    content1: {
      title: '如何使用Bid订单并参与流动性池？(中文)',
    },
    content2: {
      title: '如何往OKX Web3 Wallet导入钱包与购买$RDEX',
      detail: '更多详情',
    },
    content3: {
      title: '创建Bid/Ask及买卖教程(中文)',
    },
    content4: {
      title: 'Orders V2 操作流程 - 订单簿模块',
    },
  },
  transaction: {
    orders: {
      title: '订单表',
      price: '价格',
      vol: '数量',
      total: '成交额',
    },
    table: {
      type: '类型',
      price: '价格',
      vol: '数量',
      total: '委托总额',
      time: '时间',
      state: '状态',
      operation: '操作',
      revoke: '撤销',
      myorders: '我的委托',
      allorders: '委托历史',
      sellLimit: '限价卖出',
      buyAtCurrent: '现价买入',
    },
    rate: {
      priceChangePercent: '24h percentage',
      highest: '24h highest',
      lowest: '24h lowest',
      volumn: '24h volume',
      all: '全部',
      search: '搜索',
      custom: '自定义',
      pair: '交易对',
      latestPrice: '最新价',
      percentage: '涨幅',
    },
    trade: {
      buy: '买入',
      sell: '卖出',
      limitOrder: '限价委托',
      marketOrder: '市价委托',
      amount: '数量',
      quantity: '数量',
      totalPrice: '总价',
      serviceCharge: '手续费',
      balance: '可用',
      price: '金额',
      confirmToContinue: '是否继续进行交易',
    },
    history: {
      title: '成交记录',
      date: '日期',
      price: '价格',
      amount: '数量',
    },
  },
};
