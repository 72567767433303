import store from '@/store';

// check the chrome plugin can use
export function checkWalletCanUse(type) {
  if (type === 'unisat' && !window.unisat) {
    throw new Error('unisat is not exist, please install the plugin from chrome');
  }
}

// get wallet account
export async function getWalletAccount(type) {
  // connect unisat
  if (type === 'unisat') {
    const address = await window.unisat
      .requestAccounts()
      .then((res) => {
        if (res && res.length) {
          return res[0];
        }
      })
      .catch((err) => {
        console.error(err);
        return;
      });
    if (!address) {
      throw new Error('can not connect unisat account, please check the chrome plugin');
    }

    const publicKey = await window.unisat.getPublicKey().catch((err) => {
      console.error(err);
      return;
    });
    if (!publicKey) {
      throw new Error('can not get unisat publicKey, please check the chrome plugin');
    }

    const network = await window.unisat
      .getNetwork()
      .then((res) => {
        return res === 'livenet' ? 'mainnet' : res;
      })
      .catch((err) => {
        console.error(err);
        return;
      });
    if (!network) {
      throw new Error('can not get unisat network, please check the chrome plugin');
    }

    return {
      name: address,
      address,
      publicKey,
      network,
      connectType: type,
      avatar: '/img/icon-unisat.png',
      connectTime: Date.now(),
    };
  }
}

// connect the wallet
export async function connectWallet(type) {
  checkWalletCanUse(type);

  const accountInfo = await getWalletAccount(type);
  store.dispatch('user/setInfo', accountInfo);
  store.dispatch('connect/setConnectedState', 1);
  store.dispatch('connect/setNetwork', accountInfo.network);

  localStorage.setItem('lastConnectType', type);
  localStorage.setItem('lastConnectTime', accountInfo.connectTime);
}

// reconnect the wallet
export async function restoreConnect() {
  const lastConnectType = localStorage.getItem('lastConnectType');
  const lastConnectTime = +localStorage.getItem('lastConnectTime');

  if (!lastConnectType || !lastConnectTime) {
    return;
  }

  // 4 hour
  if (Date.now() - lastConnectTime > 14400000) {
    localStorage.removeItem('lastConnectType');
    localStorage.removeItem('lastConnectTime');
    return;
  }

  await connectWallet(lastConnectType);
}

// change network
export async function switchNetwork(network) {
  const accountInfo = store.state.user.info;
  const { connectType } = accountInfo;

  checkWalletCanUse(connectType);

  if (connectType === 'unisat') {
    if (network === 'mainnet') {
      network = 'livenet';
    }
    await window.unisat.switchNetwork(network).catch((err) => {
      console.error(err);
      return;
    });
  }
}

// get balance
export async function getBalance() {
  const accountInfo = store.state.user.info;
  const { connectType } = accountInfo;

  checkWalletCanUse(connectType);

  if (connectType === 'unisat') {
    const res = await window.unisat.getBalance().catch((err) => {
      console.error(err);
      return;
    });
    return res;
  }
}
